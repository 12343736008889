import React, {Component} from 'react';
import {Container} from "@material-ui/core";
import {AsciiMorph} from "../scripts/asciMorph";

class AsciArtComponent extends Component {
    componentDidMount() {
        document.body.classList = [];
        document.body.classList.add('asciArt');


        var element = document.querySelector('pre');
        AsciiMorph(element, {x: 51,y: 28});

        var asciis = [
            ["                                         )  (  (    (" ,
            "                                         (  )  () @@  )  (( (" ,
            "                                     (      (  )( @@  (  )) ) (" ,
            "                                   (    (  ( ()( /---\\   (()( (" ,
            "     _______                            )  ) )(@ !O O! )@@  ( ) ) )" ,
            "    <   ____)                      ) (  ( )( ()@ \\ o / (@@@@@ ( ()( )" ,
            " /--|  |(  o|                     (  )  ) ((@@(@@ !o! @@@@(@@@@@)() (" ,
            "|   >   \\___|                      ) ( @)@@)@ /---\\-/---\\ )@@@@@()( )" ,
            "|  /---------+                    (@@@@)@@@( // /-----\\ \\\\ @@@)@@@@@(  ." ,
            "| |    \\ =========______/|@@@@@@@@@@@@@(@@@ // @ /---\\ @ \\\\ @(@@@(@@@ .  ." ,
            "|  \\   \\\\=========------\\|@@@@@@@@@@@@@@@@@ O @@@ /-\\ @@@ O @@(@@)@@ @   ." ,
            "|   \\   \\----+--\\-)))           @@@@@@@@@@ !! @@@@ % @@@@ !! @@)@@@ .. ." ,
            "|   |\\______|_)))/             .    @@@@@@ !! @@ /---\\ @@ !! @@(@@@ @ . ." ,
            " \\__==========           *        .    @@ /MM  /\\O   O/\\  MM\\ @@@@@@@. ." ,
            "    |   |-\\   \\          (       .      @ !!!  !! \\-/ !!  !!! @@@@@ ." ,
            "    |   |  \\   \\          )   -cfbd-   .  @@@@ !!     !!  .(. @.  .. ." ,
            "    |   |   \\   \\        (    /   .(  . \\)). ( |O  )( O! @@@@ . )      ." ,
            "    |   |   /   /         ) (      )).  ((  .) !! ((( !! @@ (. ((. .   ." ,
            "    |   |  /   /   ()  ))   ))   .( ( ( ) ). ( !!  )( !! ) ((   ))  .." ,
            "    |   |_<   /   ( ) ( (  ) )   (( )  )).) ((/ |  (  | \\(  )) ((. )." ,
            "____<_____\\\\__\\__(___)_))_((_(____))__(_(___.oooO_____Oooo.(_(_)_)((_"]
            ,[
            "                _ooOoo_",
            "               o8888888o",
            "               88\" . \"88",
            "               (| -_- |)",
            "               O\\  =  /O",
            "            ____/`---'\\____",
            "          .'  \\\\|     |//  `.",
            "         /  \\\\|||  :  |||//  \\",
            "        /  _||||| -:- |||||_  \\",
            "        |   | \\\\\\  -  /'| |   |",
            "        | \\_|  `\\`---'//  |_/ |",
            "        \\  .-\\__ `-. -'__/-.  /",
            "      ___`. .'  /--.--\\  `. .'___",
            "   .\"\" '<  `.___\\_<|>_/___.' _> \\\"\".",
            "  | | :  `- \\`. ;`. _/; .'/ /  .' ; |",
            "  \\  \\ `-.   \\_\\_`. _.'_/_/  -' _.' /",
            "===`-.`___`-.__\\ \\___  /__.-'_.'_.-'===",
            "                `=--=-'    "
        ],
        ["                           |~~~~~~~|" ,
        "                           |       |" ,
        "                         |~~~~~~~~~~~| " ,
        "                         |  I N R I  |" ,
        "                         |___________|" ,
        "                           |       |                             " ,
        "|~.\\\\\\_\\~~~~~~~~~~~~~~xx~~~         ~~~~~~~~~~~~~~~~~~~~~/_//;~|" ,
        "|  \\  o \\_         ,XXXXX),                         _..-~ o /  |" ,
        "|    ~~\\  ~-.     XXXXX`)))),                 _.--~~   .-~~~   |" ,
        " ~~~~~~~`\\   ~\\~~~XXX' _/ ';))     |~~~~~~..-~     _.-~ ~~~~~~~ " ,
        "          `\\   ~~--`_\\~\\, ;;;\\)__.---.~~~      _.-~" ,
        "            ~-.       `:;;/;; \\          _..-~~" ,
        "               ~-._      `''        /-~-~ " ,
        "                   `\\              /  /" ,
        "                     |         ,   | |" ,
        "                      |  '        /  |" ,
        "                       \\/;          |" ,
        "                        ;;          |" ,
        "                        `;   .       |" ,
        "                        |~~~-----.....|" ,
        "                       | \\             \\" ,
        "                      | /\\~~--...__    |" ,
        "                      (|  `\\       __-\\|",
        "                      ||    \\_   /~    |" ,
        "                      |)     \\~-'      |" ,
        "                       |      | \\      '" ,
        "                       |      |  \\    :" ,
        "                        \\     |  |    |" ,
        "                         |    )  (    ) " ,
        "                          \\  /;  /\\  |" ,
        "                          |    |/   |" ,
        "                          |    |   |" ,
        "                           \\  .'  ||" ,
        "                           |  |  | |" ,
        "                           (  | |  |" ,
        "                           |   \\ \\ |" ,
        "                           || o `.)|" ,
        "                           |`\\\\\\\\) |" ,
        "                           |       |" ,
        "                           |       |" ,
        "                           |       |"],
            ["                   /\\" ,
            "                  /  \\" ,
            "                 |  _ \\                  _" ,
            "                 | / \\ \\                / \\" ,
            "                 |/   \\ \\              /   \\" ,
            "                 /     \\ |        /\\  /     \\" ,
            "                /|      \\| ~  ~  /  \\/       \\" ,
            "        _______/_|_______\\(o)(o)/___/\\_____   \\" ,
            "       /      /  |       (______)     \\    \\   \\_" ,
            "      /      /   |                     \\    \\" ,
            "     /      /    |                      \\    \\" ,
            "    /      /     |                       \\    \\" ,
            "   /     _/      |                        \\    \\" ,
            "  /             _|                         \\    \\_" ,
            "_/                                          \\" ,
            "                                             \\" ,
            "                                              \\_"],
            ["     _.-^^---....,,--       " ,
            " _--                  --_  " ,
            "<                        >)" ,
            "|                         | " ,
            " \\._                   _./  " ,
            "    ```--. . , ; .--'''       " ,
            "          | |   |             " ,
            "       .-=||  | |=-.   " ,
            "       `-=#$%&%$#=-'   " ,
            "          | ;  :|     " ,
            " _____.,-#%&$@%#&#~,._____"],
            ["   , ,, ,                              " ,
            "   | || |    ,/  _____  \\.             " ,
            "   \\_||_/    ||_/     \\_||             " ,
            "     ||       \\_| . . |_/              " ,
            "     ||         |  L  |                " ,
            "    ,||         |`==='|                " ,
            "    |>|      ___`>  -<'___             " ,
            "    |>|\\    /             \\            " ,
            "    \\>| \\  /  ,    .    .  |           " ,
            "     ||  \\/  /| .  |  . |  |           " ,
            "     ||\\  ` / | ___|___ |  |     (     " ,
            "  (( || `--'  | _______ |  |     ))  ( " ,
            "(  )\\|| (  )\\ | - --- - | -| (  ( \\  ))" ,
            "(\\/  || ))/ ( | -- - -- |  | )) )  \\(( " ,
            " ( ()||((( ())|         |  |( (( () )"]
        ,
        [
        "                            ==(W{==========-      /===-                        " ,
        "                              ||  (.--.)         /===-_---~~~~~~~~~------____  " ,
        "                              | \\_,|**|,__      |===-~___                _,-' `" ,
        "                 -==\\\\        `\\ ' `--'   ),    `//~\\\\   ~~~~`---.___.-~~      " ,
        "             ______-==|        /`\\_. .__/\\ \\    | |  \\\\           _-~`         " ,
        "       __--~~~  ,-/-==\\\\      (   | .  |~~~~|   | |   `\\        ,'             " ,
        "    _-~       /'    |  \\\\     )__/==0==-\\<>/   / /      \\      /               " ,
        "  .'        /       |   \\\\      /~\\___/~~\\/  /' /        \\   /'                " ,
        " /  ____  /         |    \\`\\.__/-~~   \\  |_/'  /          \\/'                  " ,
        "/-'~    ~~~~~---__  |     ~-/~         ( )   /'        _--~`                   " ,
        "                  \\_|      /        _) | ;  ),   __--~~                        " ,
        "                    '~~--_/      _-~/- |/ \\   '-~ \\                            " ,
        "                   {\\__--_/}    / \\\\_>-|)<__\\      \\                           " ,
        "                   /'   (_/  _-~  | |__>--<__|      |                          " ,
        "                  |   _/) )-~     | |__>--<__|      |                          " ,
        "                  / /~ ,_/       / /__>---<__/      |                          " ,
        "                 o-o _//        /-~_>---<__-~      /                           " ,
        "                 (^(~          /~_>---<__-      _-~                            " ,
        "                ,/|           /__>--<__/     _-~                               " ,
        "             ,//('(          |__>--<__|     /                  .----_          " ,
        "            ( ( '))          |__>--<__|    |                 /' _---_~\\        " ,
        "         `-)) )) (           |__>--<__|    |               /'  /     ~\\`\\      " ,
        "        ,/,'//( (             \\__>--<__\\    \\            /'  //        ||      " ,
        "      ,( ( ((, ))              ~-__>--<_~-_  ~--____---~' _/'/        /'       " ,
        "    `~/  )` ) ,/|                 ~-_~>--<_/-__       __-~ _/                  " ,
        "  ._-~//( )/ )) `                    ~~-'_/_/ /~~~~~~~__--~                    " ,
        "   ;'( ')/ ,)(                              ~~~~~~~~~~                         " ,
        "  ' ') '( (/                                                                   " ,
        "    '   '  `"]
        ];

        AsciiMorph.render(asciis[0]);

        var currentIndex = 2;

        setTimeout(function() {
            AsciiMorph.morph(asciis[1]);
        }, 1000);

        setInterval(function() {
            AsciiMorph.morph(asciis[currentIndex]);
            currentIndex++;
            currentIndex%= asciis.length;
        }, 3000);
    }

    render() {
        return (
            <Container>
                <pre className="morph-section">
                </pre>
            </Container>
        );
    }
}

export default AsciArtComponent;