import React, {Component} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/styles";
import Drawer from "@material-ui/core/Drawer";
import {List} from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SendIcon from '@material-ui/icons/Send';

import {AccessAlarm} from "@material-ui/icons";

export class NavBarComponent extends Component {
    constructor() {
        super();
        this.state = {
            drawerOpen: false
        }
    }


    toggleDrawer = (open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({...this.state, drawerOpen: open});
    };

    render() {
        return (
            <div>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton onClick={this.toggleDrawer(true)} edge="start" color="inherit" aria-label="menu">
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6">
                            News
                        </Typography>
                        <Button color="inherit">Login</Button>
                    </Toolbar>
                </AppBar>

                <Drawer open={this.state.drawerOpen} onClose={this.toggleDrawer(false)}>
                    <ListItem button>
                        <ListItemIcon>
                            <SendIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Sent mail"/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon>
                            <AccessAlarm/>
                        </ListItemIcon>
                        <ListItemText primary="Sent mail"/>
                    </ListItem>
                </Drawer>
            </div>
        );
    }
}

export default NavBarComponent;