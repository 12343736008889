import React, {Component} from 'react';

class TreeTripComponent extends Component {
    componentDidMount() {
        document.body.classList = [];
        document.body.classList.add('treeTrip');
    }

    render() {
        return (
            <div>

            </div>
        );
    }
}

export default TreeTripComponent;