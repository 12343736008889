import React, {Component} from 'react';
import CanvasDraw from "react-canvas-draw";
import {Container} from "@material-ui/core";
import {Route} from "react-router-dom";
import ArtComponent from "./ArtComponent";

class DrawingComponent extends Component {

    componentDidMount() {
        document.body.classList = [];
        document.body.classList.add('draw');
    }

    render() {
        return (
            <Container className='mainContainer' maxWidth={null}>
            <CanvasDraw
                brushColor={'red'}
                brushRadius={4}
                onChange={e => {
                    this.logoutput(e)
                }}
                style={{
                    height: '100%',
                    width: '100%',
                    boxShadow:
                        "0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)"
                }}/>
            </Container>
        );
    }
    logoutput(e) {
        console.log(e)
    }
}
export default DrawingComponent;
