import React from 'react';
import './App.css';
import DrawingComponent from './components/DrawingComponent';
import ArtComponent from "./components/ArtComponent";
import TreeTripComponent from "./components/TreeTripComponent";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import AsciArtComponent from "./components/AsciArtComponent";

import {NavBarComponent} from "./components/NavBarComponent";

function App() {
    return (
        <div>
            {/*    <NavBarComponent/>*/}
            <Router>
                <Switch>
                    <Route path="/art">
                        <ArtComponent/>
                    </Route>
                    <Route path="/draw">
                        <DrawingComponent/>
                    </Route>
                    <Route path="/treetrip">
                        <TreeTripComponent/>
                    </Route>
                    {/*Used to be /asci -testdeploying with zeit*/}
                    <Route path="/">
                        <AsciArtComponent/>
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
