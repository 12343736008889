import React, {Component} from 'react';
import '../style/sass/art.scss';
import {Container} from "@material-ui/core";
class ArtComponent extends Component {

    componentDidMount() {
        document.body.classList = [];
        document.body.classList.add('spaceTriangeles');
    }

    render() {
        return (
            <Container className="wrap">
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
                <div className="tri" />
            </Container>
        );
    }
}

export default ArtComponent;